import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import setting from "../setting.json";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Row, Card, CardBody, Button } from "react-bootstrap";

const schema = yup.object().shape({
    uhi_number: yup.string().required("Please enter value"),
    admission: yup.string().required("Please enter value"),
    image: yup.string().required("Please enter value"),
});

const SamarthPortal = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });


    return (
        <>
            <Header />

            <section className="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div className="container">
                    <div className="page-header-content">
                        <h1 className="title">Samarth Portal Verification</h1>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>

                            <li> Samarth Portal Verification </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="feedback-title">Samarth Portal Verification</h3>
                        {/* <p className="message">{message}</p> */}
                        <br />
                        <Form onSubmit={handleSubmit()}>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="UHI Number" className="text"
                                    {...register("stu_name")}
                                />
                                <p>{errors.stu_name?.message}</p>

                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Admission Number" className="text"
                                    {...register("adm_no")}
                                />
                                {errors.adm_no && <p>{errors.adm_no.message}</p>}
                            </Form.Group>

                            <Form.Group controlId="formFile" className="mb-3">
                                {/* <Form.Label>Upload file </Form.Label> */}
                                <Form.Control type="file" className="text" {...register('image')} />
                                {errors.image && <p>{errors.image.message}</p>}
                            </Form.Group>

                            <div className="form-group">
                                <button type="submit" className="d-block custom-button">
                                    <span>Submit Now</span>
                                </button>
                            </div>

                        </Form>



                    </div>
                </div>
            </div >

            <Footer />
        </>
    );
}

export default SamarthPortal;