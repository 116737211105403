import React, { useEffect, useState } from 'react';
import Footer from "../component/Footer";
import Header from "../component/Header";
import Card from "react-bootstrap/Card";
import setting from '../setting.json';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { CardHeader } from 'react-bootstrap';

const Index = () => {

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(5);


    const getAllNews = async () => {
        await fetch(setting.api + "api/getAllNews", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setList(u.data);

            });
    }

    const view = () => {
        navigate("/notification");
    }

    useEffect(() => {
        getAllNews();
    }, []);

    useEffect(() => {
        setNewsCount(list.length);
        setPageCount(Math.ceil(list.length / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));
        setCurrentPage(1);
    }, [list]);

    useEffect(() => {

        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage])

    return (
        <div className='col-12'>
            <Header />

            <section className="banner-section style-slide">
                <div className="banner-slider">
                    <div className="banner-item bg_img" style={{
                        backgroundImage: "URL(./images/pharma.jpg)",
                        height: "30lvh",
                    }}>
                        <div className="banner-item-inner">
                            <div className="container">
                                <div className="banner-item-wrapper">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="banner-item-content">
                                                <h2 className="banner-heading" style={{
                                                    WebkitTextStrokeColor: "white",
                                                    WebkitTextStrokeWidth: "0.5px",
                                                }}>Best Pharmacy College Sultanpur</h2>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="about-section pos-rel padding-bottom">
                <div className="top-shape-center">
                    <img src="assets/css/img/gallery1.png" alt="css" />
                </div>
                <div className="padding-top about-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 ">
                                <div className="about-thumb" style={{ border: "2px", width: "77%" }}>
                                    <img src="assets/images/about/about02.jpg" alt="about" />
                                </div>
                            </div>

                            <div class="col-lg-4 mb-50">

                                <Card
                                    style={{
                                        border: "solid 2px #2f2f30",
                                        // backgroundColor: "floralwhite"
                                    }}
                                >

                                    <Card.Body>
                                        <CardHeader style={{ backgroundColor: "floralwhite" }}><b>News & Notification</b></CardHeader>
                                        <div class="widget-body">
                                            {data && data.map((n) =>
                                                <p className="text-start mt-3" style={{ borderBottom: "solid 1px" }}><i className="fa fa-solid fa-arrow-right"></i><b>{n.description}</b>

                                                    <a href={n.description} target="_blank"><Button variant="danger" size="sm" style={{ padding: "unset", marginLeft: "4px" }}>new</Button></a>
                                                </p>
                                            )}
                                        </div>
                                        <Button variant="outline-primary" onClick={view} style={{ float: "right", marginTop: "20px" }}>View All News</Button>
                                    </Card.Body>
                                </Card>

                            </div>

                            <br />
                            <span></span>
                            <br />
                            <div className="col-lg-12">
                                <div className="about-content">
                                    <div className="section--header left-style mb-olpo col-lg-12">

                                        <h3 className="title">About us</h3>
                                        <p style={{ textAlign: 'justify', width: '100%', marginRight: '0px' }}>Acharya Chankya Mahavidyalya faculty of Pharmacy run by the Acharya Chankya Mahavidyalya Samiti with the entire infrastructure essential for a Pharmacy College as per the norms of PCI. We are having well equipped laboratories, a drug museum and a modernized and digital library </p>
                                    </div>
                                    <br />
                                    <span></span>
                                    <br />
                                    <ul className="nulla-list">
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Well Maintained Campus
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Grassed Playing Fields
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Digital Library
                                            </div>
                                        </li>

                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Well Equipped Laboratories
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Digital Seminar Hall
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Grassed Playing Fields
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Separate Common Rooms For Girls And Boys
                                            </div>
                                        </li>



                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Computer Labs With High Speed Internet Access
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Digital Projectors In Teaching Space
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Cold Water Facilities
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                First -Aid and Medical Facilities
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src="assets/images/about/icon01.png" alt="about" />
                                            </div>
                                            <div className="content">
                                                Separate Toilets for Handicapped boys and girls
                                            </div>
                                        </li>

                                        <li></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


            <div className="row office text-light justify-content-center">
                <div className="col-xl-3 col-lg-4 col-ms-4 col-sm-4 col-xs-4 office-div my-auto">
                    <img src="./images/1.jpg" className=" office-img my-2" />
                    <br />
                    <br />
                    <div className="office-span mx-2">
                        <b className="fs-5 office-name">Shri Yogi Adityanath</b>
                        <br />
                        Hon'ble Chief Minister
                        <br />
                        Government of Uttar Pradesh
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-ms-4 col-sm-4 col-xs-4 office-div my-auto">
                    <img src="./images/3.jpg" className=" office-img my-2" />
                    <br />
                    <br />
                    <div className="office-span mx-2">
                        <b className="fs-5 office-name">Shri Ashish Patel</b>
                        <br />
                        Hon'ble Minister
                        <br />
                        Technical Education, U.P.
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-ms-4 col-sm-4 col-xs-4 office-div my-auto">
                    <img src="./images/2.jpg" className=" office-img my-2" />
                    <br />
                    <br />
                    <div className="office-span mx-2">
                        <b className="fs-5 office-name">Shri Subhash Chand Sharma</b>
                        <br />
                        Principal Secretary
                        <br />
                        Technical Education Department
                    </div>
                </div>
            </div>

            <div className="row my-4 mx-2 justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12  ">
                    <Card className="img-card shadow-risen">
                        <Card.Img variant="top" src="./images/comm1.jpg" />
                    </Card>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <Card className="img-card">
                        <Card.Img variant="top" src="./images/comm2.jpg" />
                    </Card>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <Card className="img-card">
                        <Card.Img variant="top" src="./images/comm3.jpg" />
                    </Card>
                </div>
                <br />
                <span></span>
                <br />

            </div>


            <Footer />

        </div >
    );
}

export default Index;