import Footer from "../component/Footer";
import Header from "../component/Header";
import React, { useEffect, useState } from "react";
import setting from '../setting.json';

const Faculty = () => {


  const [faculty, setFaculty] = useState('');

  const getAllNews = async () => {
    await fetch(setting.api + "api/getAllFaculty", {
      method: "GET",
      mode: "cors",
    })
      .then(response => response.json())
      .then(u => {
        setFaculty(u.data);

      });
  }

  useEffect(() => {
    getAllNews();
  }, []);


  return (
    <>
      <Header />
      <section
        class="page-header bg_img"
        style={{
          backgroundImage: "URL(./images/banner2.jpg)",
          height: "30lvh",
        }}
      >
        <div class="container">
          <div class="page-header-content">
            <h1
              class="title text-light"
              style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "1px",
              }}
            >
              Faculty
            </h1>
            <ul class="breadcrumb">
              <li>
                <a className="text-light" href="/">
                  Home
                </a>
              </li>
              <li className="text-light"> Faculty</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="teacher-section py-3 bg_img ">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="section-header">
                <h3 class="title">Our Teachers</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-light">

            {faculty && faculty.map((f) =>

              <div className="col-lg-2 col-md-2 col-sm-3 col-xs-4 teachers-bg mx-1 my-1 ">
                {/* <img src={`${setting.api}uploads/faculty/${f.photo}`} */}
                <img src={f.photo}
                  className="col-12 teacher-img my-3 " alt="" />
                <b className="teacher-name">{f.name}</b>
                <br />
                <span className=" teacher-edu">
                  {f.qualification}
                  <br />
                  {f.subject}
                </span>

              </div>

            )}

          </div>

        </div>
      </section>
      <Footer />
    </>
  );
}

export default Faculty;